require('../scss/app.scss');

import $ from "jquery";

window.jQuery = $;
window.$ = $;

import * as bootstrap from 'bootstrap';

require("@fancyapps/fancybox");
require("slick-carousel");

require("icheck/icheck.min");
//require('cookieconsent');
require('jquery-validation');
require('jquery-validation/dist/localization/messages_hu.min');

$(document).ready(function () {
    let userMenu = $('#user-menu-container');
    let setContainer = function (html) {
        userMenu.html(html);
        userMenu.find('form').submit(function (event) {
            event.preventDefault();
            $.ajax({
                type: this.method,
                url: this.action,
                data: $(this).serialize(),
                success: setContainer
            });
        });
    };
    $('#user-menu').click(function (event) {
        event.preventDefault();
        $.ajax({
            type: "GET",
            url: this.href,
            success: setContainer
        });
    });

    //$('.datetimepicker').datetimepicker({'format': 'YYYY-MM-DD HH:mm'});
    $('a.fancybox-gallery').fancybox({
        'openEffect': 'none',
        'closeEffect': 'none',
        'transitionIn': 'elastic',
        'transitionOut': 'elastic',
        'speedIn': 600,
        'speedOut': 200,
        'overlayShow': false
    });
    $('.home-event-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 768,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '45px',
                slidesToShow: 2
            }
        }, {
            breakpoint: 480,
            settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '45px',
                slidesToShow: 1
            }
        }]
    });
    let locForm = $('#list_search_form');
    locForm.find('select').on('change', function () {
        if ('country' === $(this).attr('name')) {
            $('select#county option:selected').removeAttr('selected');
        }
        locForm.submit();
    });

    if ($('#home-locations-map').length) {
        let setHomeLocationsMap = null;
        let addEventToHomeLocationMap = function () {
            $('#home-locations-map a.nav-link').click(function (event) {
                event.preventDefault();
                $.ajax({type: "GET", url: this.href, success: setHomeLocationsMap});
            });
            $('#home-locations-map form').submit(function (event) {
                event.preventDefault();
                let data = {country: $("#country").val(), county: $("#county").val()};
                $.ajax({type: "POST", url: this.action, data: data, success: setHomeLocationsMap});
            });
            let locForm = $('#list_search_form');
            locForm.find('select').on('change', function () {
                if ('country' === $(this).attr('name')) {
                    $('select#county option:selected').removeAttr('selected');
                }
                locForm.submit();
            });
        };
        setHomeLocationsMap = function (data) {
            $('#home-locations-map').html(data);
            addEventToHomeLocationMap();
            window.initMap();
        };
        addEventToHomeLocationMap();
    }

    $.validator.addMethod('team', function (value, element) {
        return this.optional(element) || /^[a-zA-ZáéíóöőúüűÁÉÍÓÖŐÚÜŰ ]{5,15}\d{1}$/.test(value);
    }, 'A csapatnév 5-15 betű vagy szóköz karaktert és A VÉGÉN egy számot tartalmazzon');

    $('<span style="color:red;">*</span>').insertAfter('.required');

    $.validator.addMethod('phone', function (value, element) {
        return this.optional(element) || /^\+36(?:(?:(?:1|20|30|31|50|70)[1-9]\d{6})|[1-9]\d{7})$/.test(value);
    }, 'Hibás telefonszám formátum. A helyes formátum: +36301234567');


    $('form#teamRegistration').validate({
        ignore: [],
        rules: {
            'form[name]': {
                required: true,
                minlength: 6,
                team: true
            },
            'form[city]': {
                required: true
            },
            'form[email]': {
                required: true,
                email: true
            },
            'form[teamMates][0][name]': {
                required: true
            },
            'form[teamMates][0][email]': {
                required: true,
                email: true
            },
            'form[teamMates][0][phone]': {
                phone: true
            },
            'form[teamMates][0][university]': {
                required: true
            },
            'form[teamMates][0][semester]': {
                required: true
            },
            'form[teamMates][1][name]': {
                required: true
            },
            'form[teamMates][1][email]': {
                required: true,
                email: true
            },
            'form[teamMates][1][university]': {
                required: true
            },
            'form[teamMates][1][semester]': {
                required: true
            },
            'form[teamMates][2][name]': {
                required: true
            },
            'form[teamMates][2][email]': {
                required: true,
                email: true
            },
            'form[teamMates][2][university]': {
                required: true
            },
            'form[teamMates][2][semester]': {
                required: true
            },
            'form[teamMates][3][name]': {
                required: true
            },
            'form[teamMates][3][email]': {
                required: true,
                email: true
            },
            'form[teamMates][3][university]': {
                required: true
            },
            'form[teamMates][3][semester]': {
                required: true
            },
        },
        messages: {
            'form[name]': {
                required: "A mező kitöltése kötelező.",
                minlength: "Legalább 6 karakter.",
                // team: "A csapatnév 5-15 betű vagy szóköz karaktert és A VÉGÉN egy számot tartalmazzon..."
            },
            'form[city]': {
                required: "A mező kitöltése kötelező."
            },
            'form[email]': {
                required: "Az e-mail cím megadása kötelező",
                email: "Az e-mail cím formátuma helytelen"
            },
            'form[teamMates][0][name]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][0][email]': {
                required: "A mező kitöltése kötelező.",
                email: "Az e-mail cím formátuma helytelen"
            },
            'form[teamMates][0][university]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][0][semester]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][1][name]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][1][email]': {
                required: "A mező kitöltése kötelező.",
                email: "Az e-mail cím formátuma helytelen"
            },
            'form[teamMates][1][university]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][1][semester]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][2][name]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][2][email]': {
                required: "A mező kitöltése kötelező.",
                email: "Az e-mail cím formátuma helytelen"
            },
            'form[teamMates][2][university]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][2][semester]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][3][name]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][3][email]': {
                required: "A mező kitöltése kötelező.",
                email: "Az e-mail cím formátuma helytelen"
            },
            'form[teamMates][3][university]': {
                required: "A mező kitöltése kötelező."
            },
            'form[teamMates][3][semester]': {
                required: "A mező kitöltése kötelező."
            },
        },
        errorElement: "span"
    });

    /*window.setTimeout(function() {
        $('.tab-content > .tab-pane').show()
    }, 4000);*/
   /* $('form#teamRegistration').submit(function () {
        window.setTimeout(function() {
            $('.tab-content > .tab-pane').show()
        }, 100);
        window.setTimeout(function() {
            $('.tab-content > .tab-pane:not(.active)').hide()
        }, 200);
    });*/
    $('#form_submit').on('click', function (e) {
        $("#teamRegistration").valid();
        var ele = $("#teamRegistration :input.error:first");
        if (ele.is(':hidden')) {
            var tabToShow = ele.closest('.tab-pane').attr('id');
            $('button[data-bs-target="#' + tabToShow + '"]').trigger('click');
        }
    });
    /*$('form#teamRegistration').submit(function () {
        //alert('aa');
        $('.nav-tabs >.nav-item > .nav-link.active').next('li').find('button').trigger('click');
    });*/
    $( ".hide-email" ).click(function(e) {
        e.preventDefault();
        var r =  $(this).attr('data-href');
        r = r.replace('!!', '@mcc.hu');
        r = r.replace('##', '@');
        $(this).attr('href','mailto: '+r).text(r);
    });

});

window.initCroppedImg = function (event) {
    $('img.item-img-cropped').each(function (index, element) {
        if (element.clientWidth > 0 && element.naturalWidth > element.naturalHeight) {
            element.height = element.clientWidth;
            return;
        }
        if (element.height > 0 && element.naturalHeight > element.naturalWidth) {
            element.width = element.height;
            if (element.clientHeight > element.clientWidth) {
                element.height = element.width > element.clientWidth ? element.clientWidth : element.width;
            }
        }
    });
};

$(window).on("resize", window.initCroppedImg);
$(document).ready(window.initCroppedImg);
$(document).ready(function () {
    $('body').bind('DOMNodeInserted DOMNodeRemoved', window.initCroppedImg);
});
